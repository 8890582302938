<template>
  <div id="login">
    <div class="container">
      <div v-if="loading" class="login-card">
        <img class="left" :src="backgroundImgSrc" />
        <div class="right">
          <div class="top">
            <div class="logo">
              <img :src="logoSrc" />
            </div>
          </div>
          <div class="head">
            <h2
              v-for="t in titleArr"
              :key="t.active"
              class="title"
              :class="{ active: activeName === t.active }"
              @click="toggleCompoent(t.active)"
            >
              {{ t.title }}
            </h2>
          </div>
          <div class="main">
            <el-form
              label-position="top"
              v-show="activeName === 'password'"
              :model="passwordForm"
              :rules="rules"
              ref="passwordForm"
            >
              <!-- 账号 -->
              <el-form-item prop="user" label="账号：">
                <el-input
                  placeholder="请输入账号"
                  v-model="passwordForm.user"
                  autocomplete="on"
                  name="user"
                ></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item prop="pass" label="密码：">
                <el-input
                  type="password"
                  v-model="passwordForm.pass"
                  placeholder="请输入密码"
                  autocomplete="off"
                  name="pass"
                  show-password
                ></el-input>
              </el-form-item>
              <!-- 验证码 -->
              <el-form-item label="验证码：">
                <el-row :gutter="10">
                  <el-col :span="14">
                    <el-form-item style="margin-bottom: 0px" prop="vecode">
                      <el-input
                        v-model="passwordForm.vecode"
                        placeholder="请输入验证码"
                        name="code"
                        clearable
                        @keyup.enter.native="submitForm('passwordForm')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <img
                      class="code-img"
                      :src="captchaSrc"
                      alt="验证码"
                      @click="getVeCode"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- 提交 -->
              <el-form-item class="submit-item">
                <el-button
                  class="submit"
                  type="primary"
                  @click="submitForm('passwordForm')"
                  :loading="submitLoad"
                >
                  <span v-if="!submitLoad">登录</span>
                  <span v-else>登录中...</span>
                </el-button>
              </el-form-item>
            </el-form>
            <div
              class="qr flex-col flex-align-center"
              v-show="activeName === 'QRcode'"
            >
              <div class="qr-title flex-center">
                <img class="qr-title-icon" src="../assets/qr-icon.png" />
                <span class="qr-title-text">打开微信扫码登录</span>
              </div>
              <div v-loading="QRcode.loading" class="qr-code flex-center">
                <img
                  :class="[QRcode.refresh ? 'mask' : '']"
                  v-show="QRcode.mp_login_qrcode"
                  class="qr-code-img"
                  :src="QRcode.mp_login_qrcode"
                />
                <div
                  @click="getMpLoginQrcode"
                  class="qr-code-refresh flex-center flex-col"
                  v-show="QRcode.refresh"
                >
                  <i style="font-size: 40px;"
                    class="el-icon-refresh-right"
                  /><span style="text-align: center">二维码已经过期，点击<br/>刷新二维码</span>
                </div>
              </div>
            </div>

            <el-form
              v-show="activeName === 'phone'"
              :model="phoneForm"
              :rules="rules"
              label-position="top"
              ref="phoneForm"
            >
              <!-- 账号 -->
              <el-form-item prop="phone" label="手机号：">
                <el-input
                  placeholder="请输入手机号"
                  v-model="phoneForm.phone"
                  autocomplete="on"
                  name="phone"
                ></el-input>
              </el-form-item>
              <!-- 验证码 -->
              <el-form-item label="短信验证码：" class="code">
                <el-row :gutter="10">
                  <el-col :span="14">
                    <el-form-item style="margin-bottom: 0px" prop="code">
                      <el-input
                        v-model="phoneForm.code"
                        placeholder="请输入短信验证码"
                        name="code"
                        clearable
                        @keyup.enter.native="submitForm('phoneForm')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <phone-code
                      v-if="activeName === 'phone'"
                      :phone="phoneForm.phone"
                    />
                  </el-col>
                </el-row>

                <!-- 获取验证码 -->
              </el-form-item>
              <!-- 提交 -->
              <el-form-item style="margin-top: 144px" class="submit-item">
                <el-button
                  class="submit"
                  type="primary"
                  @click="submitForm('phoneForm')"
                  :loading="submitLoad"
                >
                  <span v-if="!submitLoad">登录</span>
                  <span v-else>登录中...</span>
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-if="loginTitle" class="login-title">
         {{ loginTitle }}管理后台
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import regs from '@/base/utils/regs'
import { uuid } from '@/base/utils/tool'
import PhoneCode from '../components/PhoneCode'
import {
  login,
  loginConfig,
  getCaptcha,
  getMpLoginQrcode,
  getMpLogin,
  smsLogin,
} from '../api/login'
import store from '@/base/store'

import { rsaEncrypt } from '@/base/utils/tool'

export default {
  components: { PhoneCode },
  data() {
    return {
      titleArr: [
        { title: '密码登录', active: 'password' },
        { title: '扫码登录', active: 'QRcode' },
        { title: '短信登录', active: 'phone' },
      ],
      activeName: 'password', //选中的组件名称
      loading: false,
      logoSrc: '',
      backgroundImgSrc: '',
      loginTitle: '',
      captchaSrc: '',
      captchaId: '',
      passwordForm: {
        user: '',
        pass: '',
        vecode: '',
      },
      phoneForm: {
        phone: '',
        code: '',
      },
      phone: {},
      rules: {
        user: [
          { required: false, message: '请输入账号', trigger: 'blur' },
          { validator: regs.notSpace('账号'), trigger: 'blur' },
          { min: 3, max: 32, message: '账号长度在3-32个字符', trigger: 'blur' },
        ],
        pass: [
          { required: false, message: '请输入密码', trigger: 'blur' },
          { validator: regs.notSpace('密码'), trigger: 'blur' },
          { min: 3, max: 32, message: '密码长度在3-32个字符', trigger: 'blur' },
        ],

        vecode: [{ required: false, message: '请输入验证码', trigger: 'blur' }],
        phone: [
          { required: false, message: '请输入手机号', trigger: 'blur' },
          { validator: regs.phone, trigger: 'blur' },
        ],
        code: [{ required: false, message: '请输入验证码', trigger: 'blur' }],
      },
      submitLoad: false,
      QRcode: {
        mp_login_code: '',
        mp_login_qrcode: '',
        loading: false,
        Timeout: null,
        refresh: false,
      },
    }
  },
  watch: {
    activeName(val) {
      if (val === 'QRcode') {
        if (!this.QRcode.mp_login_code || this.QRcode.refresh) {
          this.getMpLoginQrcode()
        }
      } else {
        if (val === 'password') {
          this.getVeCode()
        }
        this.resetForm(val + 'Form')
      }
    },
  },
  methods: {
    //切换组件
    toggleCompoent(name) {
      this.activeName = name
    },
    //表单验证
    submitForm(formName) {
      this.$refs[formName].validate((valid, object) => {
        if (valid) {
          switch (formName) {
            case 'passwordForm':
              this.initLogin(formName)
              break
            case 'phoneForm':
              this.smsLogin(formName)
              break
            default:
              break
          }
        }
      })
    },
    //表单重置
    resetForm(formName) {
      console.log(formName)
      this.$refs[formName].resetFields()
      this.submitLoad = false
    },
    //密码登录
    initLogin(formName) {
      this.$store.commit('setSidebarData', [])
      this.submitLoad = true
      // 设置需要加密的字符串
      login({
        account: this.passwordForm.user,
        password: rsaEncrypt(this.passwordForm.pass),
        captcha: this.passwordForm.vecode,
        captcha_id: this.captchaId,
      })
        .then((res) => {
          const { data } = res
          store.commit('user/setToken', data.token)
          store.commit("user/setLoginMethod", this.activeName);

          this.$router.replace(
            this.$route.params.wantedRoute || { name: 'Home' }
          )
          this.$message.success(res.msg)
          if (data.status) {
            this.$alert(data.msg, '提示', {
              confirmButtonText: '确定',
              /* 以 alert 方式调用时为 false） */
              closeOnClickModal: true,
              /* 设置closeOnClickModal为true后，不声明callback时会抛出uncaught Promise错误 */
              callback: () => {},
            })
          }
          this.submitLoad = false
          this.resetForm(formName)

          if (this.$store.getters['user/token']) {
            // 开发时记得注释掉不然会频繁触发
            this.$store.commit('socket/initSocket')
          }
        })
        .catch((err) => {
          console.log(err, 'error')
          this.getVeCode()
          this.submitLoad = false
        })
    },
    //获取验证码
    getVeCode() {
      this.captchaId = uuid(16)
      getCaptcha({ codeId: this.captchaId })
        .then((res) => {
          this.captchaSrc = res.data
        })
        .catch((err) => {})
    },

    // 验证码登录
    smsLogin(formName) {
      this.$store.commit('setSidebarData', [])
      this.submitLoad = true
      smsLogin(this.phoneForm)
        .then((res) => {
          const { data } = res
          store.commit('user/setToken', data.token)
          store.commit("user/setLoginMethod", this.activeName);

          this.$router.replace(
            this.$route.params.wantedRoute || { name: 'Home' }
          )
          this.$message.success(res.msg)
          if (data.status) {
            this.$alert(data.msg, '提示', {
              confirmButtonText: '确定',
              /* 以 alert 方式调用时为 false） */
              closeOnClickModal: true,
              /* 设置closeOnClickModal为true后，不声明callback时会抛出uncaught Promise错误 */
              callback: () => {},
            })
          }
          this.submitLoad = false
          this.resetForm(formName)

          if (this.$store.getters['user/token']) {
            // 开发时记得注释掉不然会频繁触发
            this.$store.commit('socket/initSocket')
          }
        })
        .catch((err) => {
          console.log(err, 'error')
          this.getVeCode()
          this.submitLoad = false
        })
    },
    // 发送短信
    smsLoginCoden() {},
    //微信登录
    mpLogin() {
      clearTimeout(this.QRcode.Timeout)
      this.QRcode.Timeout = null
      this.QRcode.Timeout = setTimeout(() => {
        getMpLogin({ code: this.QRcode.mp_login_code })
          .then((res) => {
            console.log(res)
            const { data } = res
            if (data.login_status === 0) {
              this.mpLogin()
            } else if (data.login_status === 2) {
              this.QRcode.refresh = true
            } else {
              this.$store.commit('setSidebarData', [])
              store.commit('user/setToken', data.token)
              store.commit("user/setLoginMethod", this.activeName);

              this.$router.replace(
                this.$route.params.wantedRoute || { name: 'Home' }
              )
              this.$message.success(res.msg)
              if (data.status) {
                this.$alert(data.msg, '提示', {
                  confirmButtonText: '确定',
                  /* 以 alert 方式调用时为 false） */
                  closeOnClickModal: true,
                  /* 设置closeOnClickModal为true后，不声明callback时会抛出uncaught Promise错误 */
                  callback: () => {},
                })
              }

              if (this.$store.getters['user/token']) {
                // 开发时记得注释掉不然会频繁触发
                this.$store.commit('socket/initSocket')
              }
            }
          })
          .catch((err) => {
            this.QRcode.refresh = true
          })
      }, 3000)
    },
    // 获取微信登录二维码
    getMpLoginQrcode() {
      if (this.QRcode.loading) return
      this.QRcode.loading = true
      this.QRcode.refresh = false
      clearTimeout(this.QRcode.Timeout)
      this.QRcode.Timeout = null
      getMpLoginQrcode().then((res) => {
        console.log(res)
        this.QRcode.mp_login_code = res.data.mp_login_code
        this.QRcode.mp_login_qrcode = res.data.mp_login_qrcode
        this.QRcode.loading = false
        this.mpLogin()
      })
    },
    //登录页配置
    initLoginConfig() {
      loginConfig()
        .then((res) => {
          const { data } = res
          this.logoSrc = data.login_icon
          this.backgroundImgSrc = data.login_background
          this.loginTitle = data.login_title
          this.passwordForm.user = data.demo_account ? data.demo_account : ''
          this.passwordForm.pass = data.demo_password ? data.demo_password : ''

          this.$store
            .dispatch('GET_PUB_KEY')
            .then((key) => {
              this.loading = true
            })
            .catch(() => {})
        })
        .catch(() => {
          this.loading = true
        })
    },
  },
  created() {
    // 读取上次使用的登录方式
    this.activeName = store.state.user.login_method || 'password'
    if (this.activeName === 'password') {
      // 获取验证码
      this.getVeCode()
    } else {
      // 其他方式会由 watch 监听到的
    }

    //获取登录页配置
    this.initLoginConfig()
  },
  destroyed() {
    clearTimeout(this.QRcode.Timeout)
    this.QRcode.Timeout = null
  },
}
</script>
<style lang="scss" scoped>
#login {
  height: 100vh;
  .container {
    min-height: 100vh;
    padding: 110px 0 110px;
    background: #f6f7fb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-card {
    height: 600px;
    width: 940px;
    display: flex;
    border-radius: 12px;
    box-shadow: 0 8px 32px 0 rgba(227, 233, 238, 0.5);
    background-color: #ffffff;
    position: relative;
    .left {
      height: 600px;
      width: 450px;
      border-radius: 12px 0 0 12px;
      overflow: hidden;
    }
    .right {
      min-width: 490px;
      height: 600px;
      padding: 42px;
      border-radius: 0 12px 12px 0;
      overflow: hidden;
      background-color: #fff;
      .top {
        .logo {
          margin-bottom: 45px;
          img {
            max-width: 100%;
            height: 42px;
            vertical-align: middle;
          }
        }
        // h5 {
        //   font-size: 18px;
        //   height: 24px;
        //   line-height: 24px;
        //   font-weight: bold;
        //   color: rgba(74, 144, 226, 1);
        //   margin-top: 10px;
        //   @include nowrap();
        // }
      }
      .head {
        .title {
          font-size: 18px;
          line-height: 26px;
          font-weight: 400;
          display: inline-block;
          vertical-align: bottom;
          color: #b3b3b3;
          cursor: pointer;
          &.active {
            font-size: 22px;
            line-height: 30px;
            font-weight: 500;
            color: #333;
          }
        }
        .title + .title {
          margin-left: 24px;
        }
      }
      .main {
        margin-top: 36px;
        .el-form-item {
          ::v-deep.el-form-item__label {
            height: 24px;
            line-height: 24px;
            padding: 0px;
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 400;
          }
          margin-bottom: 24px;
        }
        .code-img {
          width: 100%;
          height: 40px;
          vertical-align: middle;
          cursor: pointer;
        }
        .submit-item {
          margin-top: 48px;
          margin-bottom: 0;
        }
        .submit {
          display: block;
          width: 100%;
          height: 56px;
        }
        .qr {
          width: 406px;
          height: 363px;
          background-color: #f7f8f9;
          padding: 12px;
          border-radius: 12px;
          .qr-title {
            height: 62px;
            width: 382px;
            background-color: #fff;
            border-radius: 6px;
            .qr-title-icon {
              height: 20px;
              width: 20px;
              margin-right: 8px;
            }
            .qr-title-text {
              font-weight: 400;
              font-size: 16px;
              color: #1a1a1a;
            }
          }
          .qr-code {
            margin-top: 29px;
            height: 230px;
            width: 230px;
            border-radius: 999em;
            overflow: hidden;
            background-color: #fff;
            position: relative;
            .qr-code-refresh {
              position: absolute;
              cursor: pointer;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              position: absolute;
              background-color: rgba(255, 255, 255, 0.9);
              transition: opacity 0.3s;
            }
            .qr-code-img {
              height: 230px;
              width: 230px;
            }
          }
        }
      }
    }
    .login-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #b3b3b3;
      font-size: 18px;
      line-height: 26px;
      bottom: -39px;
    }
  }
}
</style>
