import api from "@/base/utils/request";
import { timestamp, randomString } from "@/base/utils/tool";

// 登录api
export const login = (data) => {
  return api({
    url: "/admin/admin/auth/login",
    method: "post",
    data,
    headers: {
      timestamp: timestamp(),
      nonce: randomString(16),
    },
  });
};

// 登录页配置文件
export const loginConfig = (data) => {
  return api({
    url: "/admin/admin/auth/loginConfig",
    method: "post",
    data,
  });
};

// 获取验证码
export const getCaptcha = (data) => {
  return api({
    url: "/admin/admin/index/captcha",
    method: "post",
    data,
  });
};

// 获取登录二维码
export const getMpLoginQrcode = (data) => {
  return api({
    url: "/admin/admin/auth/mpLoginQrcode",
    method: "post",
    data,
  });
};
// 二维码登录
export const getMpLogin = (data) => {
  return api({
    url: "/admin/admin/auth/mpLogin",
    method: "post",
    data,
  });
};
// 发送短信
export const smsLoginCode = (data) => {
  return api({
    url: "/admin/admin/auth/smsLoginCode",
    method: "post",
    data,
  });
};
// 短信登录
export const smsLogin = (data) => {
  return api({
    url: "admin/admin/auth/smsLogin",
    method: "post",
    data,
  });
};

