<template>
  <el-button
    :disabled="phoneCodeStatus"
    @click="getPhoneCode"
    class="code-btn"
    type="primary"
    plain
  >
    <span v-if="!phoneCodeStatus" class="get">获取验证码</span>
    <span v-else class="wait">重新发送({{ phoneCodeCount }})</span>
  </el-button>
</template>
<script>
import { smsLoginCode } from '../api/login'
export default {
  props: {
    phone: String,
  },
  data() {
    return {
      phoneCodeCount: 60, //倒计时
      phoneCodeStatus: false, //获取手机验证码状态,true:获取中，false：待获取
      phoneCodeTimer: null, //倒计时对象
    }
  },
  methods: {
    //获取手机验证码
    getPhoneCode() {
      if (!this.phone || !/^[\d]{11}$/.test(this.phone)) {
        this.$message.warning('请输入正确的电话号码')
        return
      }
      //60秒
      this.phoneCodeStatus = true
      this.createCountdown()
      //发请求
      smsLoginCode({ phone: this.phone })
        .then((res) => {
          const { data, msg } = res
          if (data.type === 0) {
            //已发送未过时，还可以使用，不需要倒计时
            this.phoneCodeStatus = false
            this.removeCountdown()
            this.$message(msg)
          }
        })
        .catch(() => {})
    },
    //创建倒计时
    createCountdown() {
      this.phoneCodeTimer = setInterval(() => {
        if (this.phoneCodeCount > 0 && this.phoneCodeCount <= 60) {
          this.phoneCodeCount--
        } else {
          this.phoneCodeStatus = false
          this.removeCountdown()
        }
      }, 1000)
    },
    //删除倒计时
    removeCountdown() {
      clearInterval(this.phoneCodeTimer)
      this.phoneCodeTimer = null
      this.phoneCodeCount = 60
    },
  },
  beforeDestroy() {
    //销毁前清除倒计时
    this.removeCountdown()
  },
}
</script>
<style lang="scss" scoped>
.code-btn {
  width: 100%;
}
</style>
